<template>
  <div class="Y1">
    <div class="banner">
      <div class="title">Y1</div>
      <div class="osDMS">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="displayAndMetal">
      <div class="itemBox">
        <div class="item">
          <div class="left">
            <div class="title">Display</div>
            <div class="text">15.6" high-definition screen</div>
            <div class="text">10 point capacitive screen</div>
          </div>
          <div class="right">
            <div class="title">Metal Material</div>
            <div class="text">Optional base can be selected</div>
          </div>
        </div>
        <div class="leftLine">
          <img src="../../../assets/Y1页面配图/左.png" alt="" />
        </div>
        <div class="rightLine">
          <img src="../../../assets/Y1页面配图/右.png" alt="" />
        </div>
      </div>
    </div>
    <div class="Dual-axis">
      <div class="title">Dual-axis foldable design</div>
      <div class="img">
        <img src="../../../assets/Y1页面配图/第三页.png" alt="" />
      </div>
    </div>
    <div class="Y1con">
      <div class="titleBox">
        <div class="title">Y1</div>
      </div>
      <div class="itemBox">
        <div class="one">
          <div class="itemA">
            <div class="title">OS</div>
            <div class="text">Android 11</div>
          </div>
          <div class="itemA">
            <div class="title">Memory</div>
            <div class="text">RAM 2GB (4GB for optinal)</div>
            <div class="text">ROM 16GB (32GB for optinal)</div>
          </div>
          <div class="itemB">
            <div class="title">CPU</div>
            <div class="text">Rock Chips RK3566</div>
            <div class="text">ARM Cortex-A55 Quad-Core, 1.8GHz</div>
          </div>
        </div>
        <div class="two">
          <div class="left">
            <div class="itemA">
              <div class="title">Display</div>
              <div class="text">10 point capacitive screen</div>
              <div class="text">15.6" high-definition screen</div>
            </div>
            <div class="itemA">
              <div class="title">Wireless Interface</div>
              <div class="text">Wi-Fi: 802.11b/g/n</div>
              <div class="text">Bluetooth: 4.0</div>
              <div class="text">4G: Optional</div>
            </div>
            <div class="itemA">
              <div class="title">Camera</div>
              <div class="text">Optional</div>
            </div>
          </div>
          <div class="right">
            <div class="itemC">
              <div class="top">
                <div class="title">Dimension</div>
                <div class="text">391mm*195mm*330 (mm)</div>
              </div>
              <div class="bottomImg">
                <div class="left textsmall">330mm</div>
                <div class="top textsmall">391mm</div>
                <div class="bottom textsmall">195mm</div>
                <div></div>
                <div></div>
                <div class="img">
                  <img src="../../../assets/Y1页面配图//Y1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="three">
          <div class="itemD">
            <div class="title">IO Ports</div>
            <div class="text">DC12V * 1</div>
            <div class="text">RJ9 * 1</div>
            <div class="text">USB2.0 * 4</div>
            <div class="text">LAN * 1</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Y1 {
  .banner {
    padding-top: 29px;
    height: 378px;
    background: url('../../../assets/Y1页面配图/首页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 37.65px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
    }
    .osDMS {
      margin: 21.95px auto 0px;
      width: 241px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 114px;
        height: 22.14px;
        background-clip: padding-box;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .displayAndMetal {
    height: 293px;
    background: url('../../../assets/Y1页面配图/第二页M.png') no-repeat center;
    background-size: cover;
    .itemBox {
      padding-top: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      .item {
        width: 355px;
        display: flex;
        justify-content: space-between;
      }
      .title {
        font-size: 12px;
        color: rgba(0, 92, 255, 1);
        line-height: 17.38px;
        margin-bottom: 3px;
      }
      .text {
        line-height: 14.48px;
        font-size: 10px;
        color: rgba(255, 255, 255, 1);
      }
      img {
        width: 100%;
        height: 100%;
      }
      .leftLine {
        width: 43px;
        height: 101px;
        position: absolute;
        top: 85px;
        left: 26px;
      }
      .rightLine {
        width: 86px;
        height: 115px;
        position: absolute;
        top: 72px;
        right: 28px;
      }
    }
  }
  .Dual-axis {
    background: rgba(0, 0, 0, 1);
    padding-top: 33px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      line-height: 23.17px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      margin-top: 14px;
      width: 290px;
      height: 163.13px;
      border-radius: 10px;
      border: 2px solid grey;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .Y1con {
    background: rgba(0, 0, 0, 1);
    .titleBox {
      margin-left: 30px;
      padding: 35px 0px 9px 0px;
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 20px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 53px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 13px;
      padding: 0px 20px;
      .title {
        font-size: 12px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
      }
      .text {
        font-size: 10px;
        font-weight: 500;
        line-height: 14.48px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 80px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        position: relative;
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 168px;
        height: 258px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          font-size: 10px;
          color: rgba(255, 255, 255, 1);
        }
        .bottomImg {
          color: rgba(255, 255, 255, 1);
          font-size: 8px;
          font-weight: 500;
          position: absolute;
          top: 100px;
          left: 10px;
          width: 145px;
          height: 135px;
          .textsmall {
            font-size: 8px;
          }
          .img {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .top {
            position: absolute;
            top: 0px;
            right: 42px;
          }
          .left {
            position: absolute;
            bottom: 64px;
            left: 0px;
          }
          .right {
            position: absolute;
            right: -20.95px;
            bottom: 36px;
          }
          .bottom {
            position: absolute;
            bottom: 0px;
            left: 78px;
          }
        }
      }
      .itemD {
        margin-top: 9px;
        padding: 3px 0px 0px 20px;
        width: 349px;
        height: 105px;
        opacity: 1;
        border-radius: 11.43px;
        background: rgba(27, 32, 48, 1);
      }
      .one {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .three {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
    }
  }
}
</style>
